//data
export const domainList = [
    {
        host: "detech",
        domain: "detech.co.kr",
    },
    {
        host: "desoft",
        domain: "desoft.co.kr",
    },
];
export const menuList = [
    {
        host: "www",
        menuName: "홈",
        menuUrl: "/home",
    },
    {
        host: "www",
        menuName: "개설하기",
        menuUrl: "/newsite",
    },
    {
        host: "sjpark",
        menuName: "홈",
        menuUrl: "/home",
    },
    {
        host: "detech",
        menuName: "홈",
        menuUrl: "/home",
    },
    {
        host: "detech",
        menuName: "contact us",
        menuUrl: "/contactUs",
    },
    {
        host: "detech",
        menuName: "기본형 게시판",
        menuUrl: "/board_qna",
    },
    {
        host: "desoft",
        menuName: "홈",
        menuUrl: "/home",
    },
    {
        host: "desoft",
        menuName: "contact us",
        menuUrl: "/contactUs",
    },
    {
        host: "desoft",
        menuName: "게시판2",
        menuUrl: "/board_faq",
    },
];
export const screenInfo = [
    {
        host: "sjpark",
        screeName: "서비스 홈(SITEOFSITE)",
        screenUrl: "home",
        screenPath: "/home",
        contentType: "STATIC",
        content: `        <div class="wrap">
    <div class="intro_bg">
        <div class="header">
            <div class="searchArea">
                <form>
                    <input type="search" placeholder="Search">
                    <span>검색</span>
                </form>
            </div>
            <ul class="nav">
                <li><a href="#">Home</a></li>
                <li><a href="#">About</a></li>
                <li><a href="#">Service</a></li>
                <li><a href="#">Contact</a></li>
            </ul>
        </div>
        <div class="intro_text">
        <h1>Practic</h1>
        <h4 class="contents1">ABCDEFGHIJK</h4>
        </div>
    </div>
</div>

<!-- amomnt start -->
<ul class="amount">
    <li>
        <div>
            <div class="contents1">지금까지 해먹은 돈</div>
            <div class="result">$ 128,021</div>
        </div>
    </li>
    <li>
        <div>
            <div class="contents1">씨를 말린 오징어 마릿수</div>
            <div class="result">93,234</div>
        </div>
    </li>
    <li>
        <div>
            <div class="contents1">최종 목표 마릿수</div>
            <div class="result">100,000+</div>
        </div>
    </li>
    <li>
        <div>
            <div class="contents1">와사비 간장</div>
            <div class="result">57,221,084</div>
        </div>
    </li>
 
     
</ul>
<!-- amount end -->

<div class="main_text0">
    <h1>About</h1>
    <div class="contents1">
        가나다라마바사
    <ul class="icons">
        <li>
            <div class="icon_img">
                <!--img src="/static/img/webhost/icon2.svg"-->
                <img src=${require("/static/img/webhost/icon2.svg")}>
            </div>
            <div class="contents1_bold">
                치밀한 계획
            </div>
            <div class="contents2">
                우리는 계획적으로 단속을 피해서 치밀하게 일을 처리합니다
            </div>
            <div class="more">
                More
            </div>
        </li>
        <li>2</li>
        <li>3</li>
    </ul> 
    </div>
</div>`,
    },
    {
        host: "detech",
        screeName: "홈",
        screenUrl: "home",
        screenPath: "/home",
        contentType: "STATIC",
        content: `
    <content>
      <div class="intro_bg" style="background-image:url('${require("/static/img/sites/intro.png")}');">
        <div class="intro_text">
          <h1>DETECH CORP</h1>
          <h5>WE MAKE WISER SOLUTION</h5>
        </div>
      </div>
      <div class="main_text1">
        <!--<h1>"디이텍은 전문가 그룹입니다."</h1>-->
        <h1>'디이텍'은 보편 타당한 상식으로
        <br>소통하는 기업입니다.</h1>
    
        <div class="contents1">
            <!--<h5>'디이텍'은 풍요로운 환경을 제공하고 긍정적인 변화를 가져오기 위해 고객과 함께하는 기업입니다.</h5>-->
            <h5>나아가 구성원 전체는 긍정과 공정의 사회를 함께 만들기 위해 노력하겠습니다. </h5>
        </div>
          <div class="flexbox">
              <div class="item">
                  <img src=${require("/static/img/sites/main2.jpg")}
                    onmouseover="this.src='${require("/static/img/sites/si.jpg")}'"
                      onmouseout="this.src='${require("/static/img/sites/main2.jpg")}'" width="420" height="300">
                  <div class="contents1_bold">
                      <h4>S.I</h4>
                  </div>
              </div>
              <div class="item">
                  <img src=${require("/static/img/sites/solution.jpg")} 
                    onmouseover="this.src='${require("/static/img/sites/sol.jpg")}'"
                      onmouseout="this.src='${require("/static/img/sites/solution.jpg")}'" width="420" height="300">
                  <div class="contents1_bold">
                      <h4>SOLUTION</h4>
                  </div>
              </div>
              <div class="item">
                  <img src=${require("/static/img/sites/ux.jpg")}
                    onmouseover="this.src='${require("/static/img/sites/ux1.jpg")}'"
                      onmouseout="this.src='${require("/static/img/sites/ux.jpg")}'" width="420" height="300">
                  <div class="contents1_bold">
                      <h4>UX</h4>
                  </div>
              </div>
              <div class="main_text2">
                  <div class="contents1">
                      <h5>SI사업에 대한 솔루션을 제시해 드리겠습니다.</h5>
                      <p>
                      <h1>SI솔루션은 디이텍과 함께하세요!</h1>
                      </p>
                      <p>HTTP://WWW.DETECH.CO.KR</p>
                  </div>
              </div>
              <!-- <div class="flexbox">
                  <div class="item"> -->
          </div>
      </div>
    </content>
    `,
    },
    {
        host: "detechx",
        screeName: "홈",
        screenUrl: "home",
        screenPath: "/home",
        contentType: "STATIC",
        content: "<div style='width:1200px;height:100px;border:1px solid;'>DETECH</div>",
    },
    {
        host: "detech",
        screeName: "contact us",
        screenUrl: "contactUs",
        screenPath: "/contactUs",
        contentType: "STATIC",
        content: `
    <content>
      <div class="cus_intro_bg" style="background-image:url('${require("/static/img/sites/contact13.jpg")}');">
        <div class="cus_intro_text">
          <h1>CONTACT US</h1>
          <h5>고객의 목소리에 귀기울이고 있습니다</h5>
        </div>
        <!-- contact_box start -->
      </div>
      <div class="cus_wrap2">
        <div class="cus_wrap2_contact">
          <div class="contact-section">
            <div class="contact-info" id="container">
              <div><a href="https://map.naver.com/v5/entry/place/386008355?c=15,0,0,0,dh"><i class="fas fa-map-marker-alt"></i></a>인천광역시 연수구 송도미래로 30 스마트밸리 D-1401</div>
              <div><a href="#"><i class="fas fa-clock"></i></a>Mon - Fri 9:00 AM to 6:00 PM</div>
              <div id="modal" class="moa"><a href="#"><i class="fas fa-envelope"></i></a>sjpark@detech.co.kr</div>
              <div><a href="#"><i class="fas fa-phone"></i></a>+82 010-9927-8654</div>
              <div><a href="#"><i class="fas fa-fax"></i></a>Fax. 0504-203-8654</div>
              <div><a href="#"><i class="fas fa-user-tie"></i></a>주식회사 DETECH CEO. 박 숭 진 이사</div>
            </div>
            <div class="contact-form">
              <h2>Contact Us</h2>
              <form class="contact" action="" method="post">
                <input type="text" name="name" class="text-box" placeholder="Your Name" required>
                <input type="email" name="email" class="text-box" placeholder="Your Email" required>
                <input type="text"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                    class="text-box" placeholder="Your Phone" required>
                <select name="select">
                    <option value="문의1" selected>A/S1</option>
                    <option value="문의2" selected>A/S2</option>
                    <option value="문의3" selected>A/S3</option>
                </select>
                <textarea name="message" rows="5" placeholder="Your Message" required></textarea>
                <div id="container">
                  <button id="btn-modal">Send</button>
                </div>
                <div id="modal" class="modal-overlay">
                  <div class="modal-window">
                    <div class="title">
                      <h2>모달</h2>
                    </div>
                    <div class="close-area">X</div>
                    <div class="content">
                      <p>가나다라마바사 아자차카타파하</p>
                      <p>가나다라마바사 아자차카타파하</p>
                      <p>가나다라마바사 아자차카타파하</p>
                      <p>가나다라마바사 아자차카타파하</p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>    
    </content>
    `,
    },
    {
        host: "desoft",
        screeName: "홈",
        screenUrl: "home",
        screenPath: "/home",
        contentType: "STATIC",
        content: "<div>DESOFT Home</div>",
    },
    {
        host: "desoft",
        screeName: "contact us",
        screenUrl: "contactUs",
        screenPath: "/contactUs",
        contentType: "STATIC",
        content: "<div>DESOFT Contact Us</div>",
    },
    {
        host: "desoft",
        screeName: "contact us",
        screenUrl: "contactUs",
        screenPath: "/board_faq",
        contentType: "STATIC",
        content: "<div>DESOFT Board FAQ</div>",
    },
];
