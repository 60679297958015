var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import * as ReactDOM from "react-dom";
// recoil
import { RecoilRoot } from 'recoil';
//utils
import * as Bridge from './utils/langs/BridgeApi';
import { oc } from 'ts-optchain';
import * as i18n from './utils/langs/i18nUtils';
//utils
import clog from "./utils/logUtils";
import ServiceMain from "./component/service/ServiceMain";
window.onload = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { result } = yield Bridge.getNativeInfo();
        clog('index.tsx : Bridge.getNativeInfo :: ' + result);
        // result 변수 안의 language 에 접근할 수 있다.
        const nativeLang = oc(result).language();
        //setLangs(nativeLang);
        //console.log('IN INDEX.HTML : nativeLang :: ', nativeLang)
        // i18n init
        yield i18n.init(nativeLang);
        console.log("index.tsx : dev mode :" + process.env.NODE_ENV);
        render();
    }
    catch (e) {
        console.error(e);
    }
});
function render() {
    ReactDOM.render(React.createElement(RecoilRoot, null,
        "    ",
        React.createElement(ServiceMain, null)), document.getElementById("app"));
}
