var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useRef, useState } from "react";
import * as CONST from "../../utils/Const";
const wssUrl = CONST.WSS_URL;
// const wssUrl = window.location.hostname == "localhost" ? "localhost:4001":"172.30.1.26:4001";
export default function CamIo() {
    const [msg, setMsg] = useState("");
    const [name, setName] = useState("");
    const [chatt, setChatt] = useState([]);
    const [chkLog, setChkLog] = useState(false);
    const [socketData, setSocketData] = useState();
    const [userList, setUserList] = useState([]);
    let user = useRef("");
    const [localDisable, setLocalDisable] = useState(false);
    let ws = useRef(null); //webSocket을 담는 변수, 
    //컴포넌트가 변경될 때 객체가 유지되어야하므로 'ref'로 저장
    let peer = useRef();
    let localVideoRef = useRef(null);
    let remoteVideoRef = useRef(null);
    const [recording, setRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioRef = useRef(null);
    var localStream = null;
    let userListRef = useRef(null);
    const msgBox = chatt.map((item, idx) => (React.createElement("div", { key: idx, className: item.name === name ? 'me' : 'other' },
        React.createElement("span", null,
            React.createElement("b", null, item.name)),
        " [ ",
        item.date,
        " ]",
        React.createElement("br", null),
        React.createElement("span", null, item.msg))));
    useEffect(() => {
        console.log(socketData);
        if (socketData !== undefined) {
            const tempData = chatt.concat(socketData);
            console.log(tempData);
            setChatt(tempData);
        }
    }, [socketData]);
    useEffect(() => {
        console.log(userList);
        let element = document.querySelector("#userList");
        userListRef.current.options.length = 0;
        for (let i = 0; i < userList.length; i++) {
            let option = document.createElement('option');
            option.innerText = userList[i];
            element.append(option);
        }
    }, [userList]);
    //webSocket
    //webSocket
    //webSocket
    //webSocket
    //webSocket
    //webSocket
    const onText = event => {
        console.log(event.target.value);
        setMsg(event.target.value);
    };
    // const webSocketLogin = useCallback(() => {
    //     console.log("소켓 연결");
    //     ws.current = new WebSocket("wss://localhost:4001/socket/chatt");
    //     ws.current.onmessage = (message) => {
    //         const dataSet = JSON.parse(message.data);
    //         setSocketData(dataSet);
    //     }
    // }, []);
    useEffect(() => {
        ws.current = new WebSocket(`wss://${wssUrl}/socket`);
        setVideoTracks();
        ws.current.onmessage = (message) => {
            console.log(message);
            let data = JSON.parse(message.data);
            let subData = data.data == null ? { type: "" } : data.data;
            if (data.type == "cam") {
                if (data.value == "ON") {
                    createOffer();
                }
            }
            else if (data.type == "userList") {
                let usrList = (data.value).substring(1);
                usrList = usrList.substring(0, usrList.length - 1).split(",");
                if (data.isOpened == "Y") {
                    user.current = data.session.split("@")[1];
                }
                let list = [];
                usrList.map((item) => {
                    list.push(item.trim().split("@")[1] + (item.indexOf(user.current) > -1 ? "(사용자)" : ""));
                });
                setUserList(list);
            }
            else if (data.type == "candidate") {
                console.log("candidate 받음");
                console.log(data.data);
                peer.current.addIceCandidate(new RTCIceCandidate(data.data)).then(() => {
                    console.log('candidate add success');
                });
                // peer.current.setRemoteDescription(data.candidate);
            }
            else if (subData.type != "") {
                if (subData.type == "offer") {
                    console.log("offer 받음");
                    console.log(data);
                    createAnswer(data);
                }
                else if (subData.type == "answer") {
                    console.log("answer 받음");
                    console.log(data);
                    peer.current.setRemoteDescription(new RTCSessionDescription(data.data));
                }
            }
        };
        setLocalDisable(true);
        // startCam();
    }, []);
    function setVideoTracks() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                localStream = yield navigator.mediaDevices.getUserMedia({ audio: true, video: { width: 320, height: 180, frameRate: 15 } });
                console.log('영상 open');
                if (localVideoRef.current)
                    localVideoRef.current.srcObject = localStream;
                peer.current = new RTCPeerConnection({
                    iceServers: [
                        {
                            urls: CONST.STUN_SERVER,
                        },
                        {
                            urls: CONST.TURN_SERVER,
                            username: CONST.TURN_USER,
                            credential: CONST.TURN_PASSWORD,
                        },
                    ],
                });
                // 자신의 video, audio track을 모두 자신의 RTCPeerConnection에 등록한다.
                localStream.getTracks().forEach(track => {
                    peer.current.addTrack(track, localStream);
                });
                peer.current.onicecandidate = (e) => {
                    if (e.candidate) {
                        console.log('onicecandidate');
                        let param = {
                            type: "candidate",
                            data: e.candidate
                        };
                        ws.current.send(JSON.stringify(param));
                    }
                };
                peer.current.oniceconnectionstatechange = (e) => {
                    console.log("oniceconnectionstatechange");
                    console.log(e);
                    console.log("oniceconnectionstatechange");
                };
                peer.current.ontrack = (ev) => {
                    console.log('add remotetrack success');
                    if (remoteVideoRef.current) {
                        remoteVideoRef.current.srcObject = ev.streams[0];
                        let audio = ev.streams[0].getAudioTracks()[0];
                        console.log('tmp');
                        console.log(audio);
                        console.log('audio');
                        // ev.streams[0].getTracks().forEach(element => {
                        //     // tmp = element;
                        //     console.log(element);
                        // });
                        // const options = { mimeType: 'audio/mpeg' };
                        // const options = { mimeType: 'video/webm' };
                        // const mediaRecorder = new MediaRecorder(tmp, options);
                        let stream = new MediaStream([audio]);
                        const mediaRecorder = new MediaRecorder(stream);
                        mediaRecorderRef.current = mediaRecorder;
                        mediaRecorder.start();
                        setRecording(true);
                        mediaRecorder.ondataavailable = (e) => {
                            console.log(e.data);
                            const url = URL.createObjectURL(e.data);
                            audioRef.current.src = url;
                            setDownUrl(url);
                        };
                    }
                };
            }
            catch (error) {
                console.error('미디어 접근에 실패했습니다.', error);
            }
        });
    }
    const createOffer = () => {
        let selected = userListRef.current.value;
        if (selected == "" || selected.indexOf(user.current.split("(")[0]) > -1) {
            console.log("연결 불가");
        }
        else {
            console.log('create offer');
            peer.current.createOffer({ offerToReceiveAudio: true, offerToReceiveVideo: true })
                .then(sdp => {
                peer.current.setLocalDescription(new RTCSessionDescription(sdp));
                let param = {
                    reception: selected,
                    sent: user.current,
                    data: sdp
                };
                ws.current.send(JSON.stringify(param));
            })
                .catch(error => {
                console.log(error);
            });
        }
    };
    const createAnswer = (data) => {
        let sdp = data.data;
        peer.current.setRemoteDescription(new RTCSessionDescription(sdp)).then(() => {
            console.log('answer set remote description success');
            peer.current.createAnswer({ offerToReceiveVideo: true, offerToReceiveAudio: true })
                .then(sdp1 => {
                console.log('create answer');
                peer.current.setLocalDescription(new RTCSessionDescription(sdp1));
                let param = {
                    reception: data.reception,
                    sent: data.sent,
                    data: sdp1
                };
                ws.current.send(JSON.stringify(param));
            })
                .catch(error => {
                console.log(error);
            });
        });
    };
    const send = useCallback(() => {
        if (!chkLog) {
            if (name === "") {
                alert("이름을 입력하세요.");
                document.getElementById("name").focus();
                return;
            }
            // webSocketLogin();
            setChkLog(true);
        }
        if (msg !== '') {
            const data = {
                name,
                msg,
                date: new Date().toLocaleString(),
            }; //전송 데이터(JSON)
            const temp = JSON.stringify(data);
            if (ws.current.readyState === 0) { //readyState는 웹 소켓 연결 상태를 나타냄
                ws.current.onopen = () => {
                    console.log(ws.current.readyState);
                    ws.current.send(temp);
                };
            }
            else {
                ws.current.send(temp);
            }
        }
        else {
            alert("메세지를 입력하세요.");
            document.getElementById("msg").focus();
            return;
        }
        setMsg("");
    }, [msg, name]);
    const reqCam = () => {
        createOffer();
    };
    //webSocket
    //webSocket
    //webSocket
    //webSocket
    //webSocket
    //webSocket
    const [downUrl, setDownUrl] = useState("");
    const recordStop = () => {
        mediaRecorderRef.current.stop();
        setRecording(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "chat-wrap" },
            React.createElement("div", { id: 'chatt' },
                React.createElement("h1", { id: "title" }, "WebSocket Cam"),
                React.createElement("div", null,
                    React.createElement("select", { id: "userList", size: 10, ref: userListRef, style: { height: "200px", width: "160px", margin: "10px" } }),
                    React.createElement("input", { type: "button", onClick: reqCam, value: "\uCEA0 \uC694\uCCAD" })),
                React.createElement("div", { id: "remoteStreamDiv" },
                    React.createElement("video", { id: "localStream", autoPlay: true, playsInline: true, controls: true, ref: localVideoRef }),
                    React.createElement("video", { id: "remotevideo", autoPlay: true, playsInline: true, controls: true, ref: remoteVideoRef }),
                    React.createElement("audio", { ref: audioRef, controls: true }),
                    downUrl != "" ? React.createElement("a", { href: downUrl, download: true }, "\uB2E4\uC6B4\uB85C\uB4DC") : "",
                    React.createElement("button", { onClick: recordStop }, "\uB179\uD654\uC911\uC9C0"))))));
}
