import * as env from "./envUtils";
export const defWidth = 1920;
//LIST COUNT
export const NUM_WLISTCNT = 10;
export const NUM_MLISTCNT = 3;
//AUTO LOGIN
export const NUM_DIFFTIME_SEC_DEV = 600; //85000;
export const NUM_DIFFTIME_SEC_PROD = 600;
export const NUM_DIFFTIME_SEC = env.isDevelopmentMode
    ? NUM_DIFFTIME_SEC_DEV
    : NUM_DIFFTIME_SEC_PROD;
//LANGUAGES
export const STR_LANG_KOR = "ko_KR";
export const STR_LANG_ENG = "en_US";
export const STR_LANG_CHA = "cn_CH";
export const STR_LANG_JPA = "jp_JA";
//
export const STR_APILANG_KOR = "Korean";
export const STR_APILANG_ENG = "English";
export const STR_APILANG_CHA = "Chinese";
export const STR_APILANG_JPA = "jp_JA";
//Korean, English, Chinese
//ROLE
export const USERROLE_ADMIN = "ROLE_ADMIN";
export const USERROLE_ENGINEER = "ROLE_ENGINEER";
export const USERROLE_USER = "ROLE_USER";
export const USERROLE_NONE = "ROLE_NONE";
//LOGIN STRING KEY
export const STR_USERID = "userId";
export const STR_USERROLE = "role";
export const STR_TOKEN = "token";
export const STR_TOKEN_EXPIRETIME = "tokenExpireTime";
export const STR_REFRESHTOKEN = "refreshToken";
export const STR_REFRESHTOKEN_EXPIRETIME = "refreshTokenExpireTime";
export const STR_KEEP_LOGIN_YN = "keepLoginYn";
export const STR_EHP_MENULIST = "ehpMenuList";
export const STR_EHP_DASHBODRD_CARDORDER = "ehpDashboardCardorder";
export const STR_EHP_DASHBODRD_PIN = "ehpDashboardPin";
// URL PARAM
export const STR_PARAM_DATA = "EHP_EX_DATA";
export const STR_PARAM_PDFID = "EHP_PDF_ID";
export const STR_PARAM_PDFNM = "EHP_PDF_NM";
export const STR_EMAIL = "email";
export const STR_KEY = "key";
//MENU URL
export const URL_MAIN = "/";
export const URL_LOGIN = "/login";
//
//API RESULT
export const API_200 = 200; // HTTP 200
export const API_999 = 999; // unknown system error
//APP FIELD DATA
export const FLD_AUTOFIELD = "autofield";
export const FLD_MSG_DELETE_COMMON = "요청하신 정보가 삭제되었습니다.";
// WEB_RTC
export const WSS_URL = "www.detech.co.kr:4001";
export const STUN_SERVER = "stun:stun.l.google.com:19302";
export const TURN_SERVER = "turn:20.214.201.6:3478";
export const TURN_USER = "detech013";
export const TURN_PASSWORD = "detech!23";
