import React from "react";
// import SockJS from "sockjs-client";
// const server_url = "https://www.detech.co.kr:4001";
// const server_url = "https://172.30.1.26:4001";
// const sockJS = new SockJS(`${server_url}/signaling`);
// const stompClient:any = Stomp.over(sockJS);
export default function CamMain() {
    // const [nickname, setNickname] = useState("");
    // const [disableName, setDisableName] = useState(false);
    // const ws_url = "wss://localhost:4001";
    // let userListRef = useRef(null);
    // // const client = useRef<CompatClient>();
    // const myKey = Math.random().toString(36).substring(2, 11);
    // var roomId = "";
    // // var stompClient = new Client();
    // var localStream = undefined;
    // let pcListMap = new Map();
    // var otherKeyList = [];
    // useEffect(()=>{
    //     callUserList();
    //     // const callList = setInterval(() => {
    //     //     callUserList();
    //     // }, 10000);
    //     // return () => clearInterval(callList);
    // }, [])
    // useEffect(()=>{
    //     console.log("pcListMap");
    //     console.log(pcListMap);
    //     console.log("pcListMap");
    // }, [pcListMap])
    // useEffect(()=>{
    //     console.log("otherKeyList");
    //     console.log(otherKeyList);
    //     console.log("otherKeyList");
    // }, [otherKeyList])
    // function callUserList(){
    //     try {
    //         var request = new XMLHttpRequest();
    //         request.open('GET', `${server_url}/topic/send/userList`);
    //         request.send();
    //         request.onload = function(e) {
    //             console.log(e);
    //             let lst = JSON.parse(request.response);
    //             let userList = document.querySelector("#userList");
    //             userListRef.current.options.length = 0;
    //             for(let i=0; i<lst.length; i++){
    //                 let option = document.createElement('option');
    //                 option.innerText = lst[i];
    //                 userList.append(option);
    //             }
    //         }
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }
    // function regNickname(){
    //     if(nickname != ""){
    //         startCam();
    //         setDisableName(true);
    //         var request = new XMLHttpRequest();
    //         request.open('GET', `${server_url}/topic/call/user/${nickname}`);
    //         request.send();
    //         request.onload = function(e) {
    //             console.log(e);
    //             let lst = JSON.parse(request.response);
    //             let userList = document.querySelector("#userList");
    //             userListRef.current.options.length = 0;
    //             for(let i=0; i<lst.length; i++){
    //                 let option = document.createElement('option');
    //                 option.innerText = lst[i];
    //                 userList.append(option);
    //             }
    //             connectSocket();
    //         }
    //     }
    // }
    // function handleNickname(e){
    //     setNickname(e.target.value);
    // }
    // async function startCam(){
    //     try {
    //         const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    //         // 미디어 스트림 사용
    //         // 예: 비디오 요소에 스트림 연결
    //         let userCam:any = document.querySelector('#localStream');
    //         userCam.srcObject = stream;
    //       } catch (error) {
    //         console.error('미디어 접근에 실패했습니다.', error);
    //       }
    // }
    // function connectSocket(){
    //     stompClient.configure({
    //     //   brokerURL: `${ws_url}/signaling`,
    //       webSocketFactory: () => new SockJS(`${server_url}/signaling`),
    //       debug: (str) => { console.log(str); },
    //       reconnectDelay: 5000,
    //       heartbeatIncoming: 4000,
    //       heartbeatOutgoing: 4000,
    //       onConnect: (e) => {
    //         console.log("연결완료");
    //         console.log(e);
    //         console.log(stompClient);
    //         console.log("연결완료");
    //         stompClient.subscribe(`${server_url}/topic/peer/iceCandidate/${myKey}/${roomId}`, candidate => {
    //             const key = JSON.parse(candidate.body).key
    //             const message = JSON.parse(candidate.body).body;
    //             pcListMap.get(key).addIceCandidate(new RTCIceCandidate({candidate:message.candidate,sdpMLineIndex:message.sdpMLineIndex,sdpMid:message.sdpMid}));
    //         });
    //         stompClient.subscribe(`${server_url}/topic/peer/offer/${myKey}/${roomId}`, offer => {
    //             const key = JSON.parse(offer.body).key;
    //             const message = JSON.parse(offer.body).body;
    //             pcListMap.set(key, createPeerConnection(key));
    //             pcListMap.get(key).setRemoteDescription(new RTCSessionDescription({type:message.type,sdp:message.sdp}));
    //             sendAnswer(pcListMap.get(key), key);
    //         });
    //         stompClient.subscribe(`${server_url}/topic/peer/answer/${myKey}/${roomId}`, answer =>{
    //             const key = JSON.parse(answer.body).key;
    //             const message = JSON.parse(answer.body).body;
    //             pcListMap.get(key).setRemoteDescription(new RTCSessionDescription(message));
    //         });
    //         stompClient.subscribe(`${server_url}/topic/call/key`, message =>{
    //             stompClient.send(`${server_url}/app/send/key`, {}, JSON.stringify(myKey));
    //         });
    //         stompClient.subscribe(`${server_url}/topic/send/key`, message => {
    //             console.log("/topic/send/key 구독");
    //             const key = JSON.parse(message.body);
    //             if(myKey !== key && otherKeyList.find((mapKey) => mapKey === myKey) === undefined){
    //                 otherKeyList.push(key);
    //             }
    //         });
    //       },
    //       onStompError: (frame) => {
    //         console.error(frame);
    //       },
    //       onDisconnect: (message) => {
    //         console.log(`onDisconnect: ${message}`);
    //       },
    //     });
    //     stompClient.activate();
    // }
    // const createPeerConnection = (otherKey) =>{
    //     console.log("createPeerConnection 진입");
    //     const pc = new RTCPeerConnection();
    //     try {
    //         pc.addEventListener('icecandidate', (event) =>{
    //             onIceCandidate(event, otherKey);
    //         });
    //         pc.addEventListener('track', (event) =>{
    //             onTrack(event, otherKey);
    //         });
    //         if(localStream !== undefined){
    //             localStream.getTracks().forEach(track => {
    //                 pc.addTrack(track, localStream);
    //             });
    //         }
    //         console.log('PeerConnection created');
    //     } catch (error) {
    //         console.error('PeerConnection failed: ', error);
    //     }
    //     return pc;
    // }
    // let onIceCandidate = (event, otherKey) => {
    //     if (event.candidate) {
    //         console.log('ICE candidate');
    //         stompClient.send(`${server_url}/app/peer/iceCandidate/${otherKey}/${roomId}`,{}, JSON.stringify({
    //             key : myKey,
    //             body : event.candidate
    //         }));
    //     }
    // };
    // let sendAnswer = (pc,otherKey) => {
    //     pc.createAnswer().then( answer => {
    //         setLocalAndSendMessage(pc ,answer);
    //         stompClient.send(`${server_url}/app/peer/answer/${otherKey}/${roomId}`, {}, JSON.stringify({
    //             key : myKey,
    //             body : answer
    //         }));
    //         console.log('Send answer');
    //     });
    // };
    // //localDescription 해주는 함수
    // const setLocalAndSendMessage = (pc, sessionDescription) => {
    //     pc.setLocalDescription(sessionDescription);
    // }
    // let onTrack = (event, otherKey) => {
    //     if(document.getElementById(`${otherKey}`) === null){
    //         const video =  document.createElement('video');
    //         video.autoplay = true;
    //         video.controls = true;
    //         video.id = otherKey;
    //         video.srcObject = event.streams[0];
    //         document.getElementById('remoteStreamDiv').appendChild(video);
    //     }
    // };
    // let roomIdRef = useRef(null);
    // const enterRoom = () => {
    //     roomId = roomIdRef.current.value;
    // }
    // let startStreams = async () => {
    //     console.log("stompClient");
    //     console.log(stompClient);
    //     console.log("stompClient");
    //     console.log(roomId);
    //     await stompClient.send(`/app/call/key`, {}, {});
    //     setTimeout(() =>{
    //         otherKeyList.map((key) =>{
    //             if(!pcListMap.has(key)){
    //                 pcListMap.set(key, createPeerConnection(key));
    //                 sendOffer(pcListMap.get(key),key);
    //             }
    //         });
    //     },1000);
    // }
    // let sendOffer = (pc ,otherKey) => {
    //     console.log("sendOffer 진입");
    //     pc.createOffer().then(offer =>{
    //         setLocalAndSendMessage(pc, offer);
    //         stompClient.send(`${server_url}/app/peer/offer/${otherKey}/${roomId}`, {}, JSON.stringify({
    //             key : myKey,
    //             body : offer
    //         }));
    //         console.log('Send offer');
    //     });
    // };
    return (React.createElement(React.Fragment, null));
}
